import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { EMAIL_PATTERN } from 'src/app/helpers/emailValidation';
import { CommonService } from 'src/app/providers/common.service';
import { NgOtpInputComponent, NgOtpInputConfig } from "ng-otp-input";
import { AuthModuleService } from '../../auth-module.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService, Request, Response } from "src/app/providers/data.service";
import { CoreService } from 'src/app/providers/core.service';
import { ConstantService } from 'src/app/providers/constant.service';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from "@capacitor/push-notifications";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { Preferences } from "@capacitor/preferences";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from "@awesome-cordova-plugins/sign-in-with-apple/ngx";
import { configuration } from "src/app/configuration";
import { AuthenticationService } from 'src/app/providers/authentication.service';
import { Keyboard } from '@capacitor/keyboard';
declare var AppleID;

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  qp: string = this.navParams.get("key");
  qpstep: string = this.navParams.get("step");
  // login Form Group
  loginForm: FormGroup = new FormGroup({
    email: new FormControl<string | null>(null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
  });
  // signup Form Group
  signUpForm: FormGroup = new FormGroup({
    fullName: new FormControl<string | null>(null, [Validators.required]),
    email: new FormControl<string | null>(null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
    phone: new FormControl<number | null>(null, [Validators.required, Validators.minLength(14)]),
    termCondition: new FormControl<boolean>(false),
  });
  // social signup user-details From Group
  socialSignUpUserDetails: FormGroup = new FormGroup({
    fullName: new FormControl<string | null>(null, [Validators.required]),
    email: new FormControl<string | null>(null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]),
    phone: new FormControl<number | null>(null, [Validators.required, Validators.minLength(14)]),
  });
  // OTP Form Control 
  otpFormControl: FormControl = new FormControl<number | null>(null, [
    Validators.required,
    Validators.minLength(6),
  ]);
  // OTP Config
  otpInputConfig: NgOtpInputConfig = {
    length: 6,
    inputClass: "otpInput",
    containerClass: "optInputContainer",
    placeholder: "-",
    allowNumbersOnly: true,
    disableAutoFocus: false,
  };
  FACEBOOK_PERMISSIONS: string[] = ["email", "user_birthday", "user_photos", "user_gender"];
  @ViewChild('ngOtpInput') ngOtpInput: NgOtpInputComponent;
  isLoginFormSubmitted = false;
  isSignUpFormSubmitted = false;
  isUserDetailsSubmitted = false;
  isIOSPlatform: boolean = false;
  isdesktopPlatform: boolean = false;
  step: "login" | "signup" | "verify-otp" | "user-details" = "login";
  mode: "login" | "signup";
  timeLeft: number = 120;
  interval: any;
  voipToken: string = undefined;
  generatedToken: string | null = null;
  emailFromUrl: string = '';
  isEmailFromRoute: boolean = false;
  loginModalKeyboardOpen = false;
  loginModalScrollableMargin = 0
  // [DK, MP] Fix login modal height issue on android and ios
  // =======================================================
  isAndroid: boolean = false;
  // =======================================================

  constructor(
    private coreService: CoreService,
    private apiService: DataService,
    private constantService: ConstantService,
    private router: Router,
    public commonService: CommonService,
    private platform: Platform,
    public authModuleService: AuthModuleService,
    private afMessaging: AngularFireMessaging,
    private signInWithApple: SignInWithApple,
    public modalCtrl: ModalController,
    private cd: ChangeDetectorRef,
    public navParams: NavParams,
    public activatedRoute: ActivatedRoute,
    public authenticationService: AuthenticationService,
  ) {
    if ((typeof window) == 'undefined') return;

    // [DK, MP] Fix login modal height issue on android and ios
    // =======================================================
    this.isAndroid = this.platform.is("android");
    // =======================================================
    GoogleAuth.initialize({
      clientId: "573316732862-ihcsn2uu3cvnhq115s1ejvnbv5mko29t.apps.googleusercontent.com",
      scopes: ["profile", "email"],
    });
    // check social signin details
    let data = JSON.parse(localStorage.getItem('authDetails'))
    if (!data?.phone && this.authenticationService.isAuthenticated()) {
      this.getEmailFromLocalStorage(data)
      this.step = 'user-details';
    }
  }

  // ngOnInit
  ngOnInit() {
    if ((typeof window) == 'undefined') return;
    this.checkPlatform();
  }

  // ionViewDidEnter
  ionViewDidEnter() {
    if ((typeof window) == 'undefined') return;
  }

  // ionViewWillEnter
  ionViewWillEnter() {
    if ((typeof window) == 'undefined') return;
    this.generateNotificationToken();
    this.requestPushNotificationsPermission();
    if(this.platform.is('android') || this.platform.is('ios')) {
      Keyboard.addListener('keyboardWillShow', (info)=>{
        this.loginModalKeyboardOpen = true
        this.loginModalScrollableMargin = info.keyboardHeight
        this.cd.detectChanges()
      })
      Keyboard.addListener('keyboardWillHide', ()=>{
        this.loginModalKeyboardOpen = false
        this.loginModalScrollableMargin = 0
        this.cd.detectChanges()
      })
    } else {
      this.loginModalScrollableMargin = 300;
      this.cd.detectChanges()
    }
  }

  // ionViewDidLeave
  ionViewDidLeave(): void {
    if (this.authenticationService.isAuthenticated()) this.commonService.$navigateSubject.next();
    this.step = 'login';
    this.isLoginFormSubmitted = false;
    this.isSignUpFormSubmitted = false;
    this.isUserDetailsSubmitted = false;
    this.ngOtpInput?.setValue('');
    this.stopTimer();
    this.loginForm.reset();
    this.signUpForm.reset();
    this.socialSignUpUserDetails.reset();
    this.otpFormControl.reset();
  }

  ///////////////////////           Common step          ///////////////////////
  // check PlatForm
  checkPlatform() {
    this.isIOSPlatform = !this.commonService.platform.is("android") && !this.commonService.platform.is("desktop") ? true : false;
    this.isdesktopPlatform = this.commonService.platform.is('desktop')
  }

  // Generate Notification Token ( Mobile )
  async generateNotificationToken() {
    if (this.commonService.platform.is("desktop") || this.commonService.platform.is("mobileweb")) this.generatedToken = null;

    let result = await PushNotifications.checkPermissions();
    if (result.receive != 'granted')
      result = await PushNotifications.requestPermissions();
    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      this.generatedToken = token.value;
      // alert("Push registration success, token: " + token.value);
    });
    PushNotifications.addListener("registrationError", (error: any) => {
      // alert("Error on registration: " + JSON.stringify(error));
    });
    PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
      // alert("Push received: " + JSON.stringify(notification));
    }
    );
    PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
      // alert("Push action performed: " + JSON.stringify(notification));
    }
    );
    //this.registerVoipNotification();
    if (result.receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      this.generatedToken = null;
      // Show some error
    }
  }

  // Request Push Notifications Permission ( WEB )
  requestPushNotificationsPermission() {
    // requesting permission // getting tokens
    this.afMessaging.requestToken.subscribe((token) => {
      // USER-REQUESTED-TOKEN
      this.generatedToken = token;
    },
      (error) => {
        console.error(error);
      }
    );
  }

  // Modal Close
  onclick_cancel() {
    this.modalCtrl.dismiss();
  }

  // Check Phone Number Length
  checkPhoneNumLength(phone: any) {
    if (phone?.length > 14) return phone.substring(0, 14);
    return phone;
  }

  ///////////////////////           login step          ///////////////////////

  // Login Form Submit
  loginFormSubmit() {
    this.isLoginFormSubmitted = true;
    let voipToken = localStorage.getItem("voipToken");
    if (voipToken !== undefined && voipToken !== null && voipToken.trim() !== '') this.voipToken = voipToken;
    if (this.loginForm.invalid) return;
    let request: Request = {
      path: "auth/users/login",
      data: {
        ...this.loginForm.value,
        loginSource: "WEB",
        deviceToken: this.generatedToken,
        voipDeviceToken: this.voipToken,
      },
    };
    this.coreService.presentLoader(this.constantService.WAIT);
    this.apiService.post(request, false).subscribe((response: Response) => {
      this.coreService.dismissLoader();
      if (response.status.code === this.constantService.STATUS_OK) {
        this.isLoginFormSubmitted = false;
        this.authModuleService.loginEmail = this.loginForm.controls.email.value;
        this.mode = 'login';
        this.loginForm.reset();
        this.step = 'verify-otp';
        this.startTimer();
      } else {
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_ERROR);
      }
    });
  }

  // FaceBook SignIn
  async faceBookSignIn() {
    if (!this.commonService.platform.is("desktop")) {
      try {
        let result = (await FacebookLogin.login({ permissions: this.FACEBOOK_PERMISSIONS })) as any;
        let RequestData = {
          socialAccessToken: result.accessToken.token,
          socialLoginType: "FACEBOOK",
        };
        this.socialLogin(RequestData);
      } catch (e) {
        console.log("FaceBook SignIn Error", e);
      }
    } else {
      this.coreService.showToastMessage("Development under progress", this.coreService.TOAST_INFO);
    }
  }

  // Google SignIn
  async googleSignIn() {
    try {
      let user = await GoogleAuth.signIn();
      let RequestData = {
        socialAccessToken: user.authentication.accessToken,
        socialLoginType: "GOOGLE",
      };
      this.socialLogin(RequestData);
    } catch (e) {
      console.log('Google Signin Error', e);
    }
  }

  // Apple SignIn
  async applesign() {
    let modalElement = await this.modalCtrl.getTop();
    modalElement.backdropDismiss = false

    if (this.isIOSPlatform) {
      this.signInWithApple.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
        ],
      }).then((res: AppleSignInResponse) => {
        const appleSigninToken = res.identityToken.split('.')[0] + '.' + btoa(JSON.stringify({ ...JSON.parse(atob(res.identityToken.split('.')[1])), ...(res.fullName?.givenName && res.fullName?.familyName ? { firstName: res.fullName.givenName, lastName: res.fullName.familyName } : {}) })) + '.' + res.identityToken.split('.')[2];
        let RequestData = {
          socialAccessToken: appleSigninToken,
          socialLoginType: "APPLE",
        };
        this.socialLogin(RequestData);
      }).catch((error: AppleSignInErrorResponse) => {
        modalElement.backdropDismiss = true
        console.error('Apple SignIn Error', error);
      });
    } else if (this.isdesktopPlatform) {
      let isProd = configuration.BASE_URL.includes("prod-apis")
      AppleID.auth.init({
        clientId: 'com.bubble.bubbleapp.home',
        scope: 'name email',
        redirectURI: "https://" + (isProd ? "portal" : "dev") + '.bubbleapp.com/home/user/edit-profile',
        state: 'init',
        nonce: 'test',
        usePopup: true
      });
      const data = await AppleID.auth.signIn();
      const appleSigninToken = data.authorization.id_token.split('.')[0] + '.' + btoa(JSON.stringify({ ...JSON.parse(atob(data.authorization.id_token.split('.')[1])), ...(data?.user?.name?.firstName && data?.user?.name?.lastName ? { firstName: data.user.name?.firstName, lastName: data.user.name?.lastName } : {}) })) + '.' + data.authorization.id_token.split('.')[2];
      let RequestData = {
        socialAccessToken: appleSigninToken,
        socialLoginType: "APPLE",
      };
      this.socialLogin(RequestData);
    }
  }

  // Social Login
  socialLogin(data) {
    let voipToken = localStorage.getItem("voipToken");
    if (voipToken !== undefined && voipToken !== null && voipToken.trim() !== '') this.voipToken = voipToken;
    let request: Request = {
      path: "auth/users/login",
      data: {
        socialAccessToken: data.socialAccessToken,
        socialLoginType: data.socialLoginType,
        deviceToken: this.generatedToken,
        voipDeviceToken: this.voipToken,
      },
    };
    this.coreService.presentLoader(this.constantService.WAIT);
    this.apiService.post(request, false).subscribe((response: Response) => {
      this.coreService.dismissLoader();
      if (response.status.code === this.constantService.STATUS_OK) {
        localStorage.setItem("authDetails", JSON.stringify(response.data));
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_SUCCESS);
        Preferences.set({ key: "userDetails", value: JSON.stringify(response.data) }).then(() => {
          this.getEmailFromLocalStorage(response.data)
          if (response?.data?.phone) {
            this.modalCtrl.dismiss();
            if (this.qp && this.qp.trim() != '') this.router.navigateByUrl(this.qp)
          } else {
            this.step = 'user-details';
          }
        });
      } else {
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_ERROR);
      }
    });
  }

  // Open Create BubbleApp Account Modal
  createBubbleAppAccount() {
    this.step = 'signup';
    this.loginForm.reset();
  }

  ///////////////////////           Create New BubbleApp Account / SignUp Step          ///////////////////////

  // Create BubbleApp Account Form Submit
  signUpFormSubmit() {
    this.isSignUpFormSubmitted = true;
    if (this.signUpForm.invalid) return;
    if (this.isEmailFromRoute) this.signUpForm.value.email = this.emailFromUrl;
    let request: Request = {
      path: "auth/users/signUp",
      data: { ...this.signUpForm.value },
    };
    this.coreService.presentLoader(this.constantService.WAIT);
    this.apiService.post(request).subscribe((response: Response) => {
      this.coreService.dismissLoader();
      if (response.status.code === this.constantService.STATUS_OK) {
        this.authModuleService.signUpData = this.signUpForm.value;
        if (this.isEmailFromRoute) this.authModuleService.signUpWithInviteLinkDetails = response['data'];
        this.mode = 'signup';
        this.signUpForm.reset();
        this.step = 'verify-otp';
        this.startTimer();
      } else {
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_ERROR);
      }
    });
  }

  ///////////////////////           Verify-Otp Step          ///////////////////////

  // Verify-OTP
  verifyActivateAccountOtp() {
    if (this.validateOtp()) return;
    let voipToken = localStorage.getItem("voipToken");
    if (voipToken !== undefined && voipToken !== null && voipToken.trim() !== '') this.voipToken = voipToken;
    let request: Request
    if (this.platform.is("ios")) {
      request = {
        path: "auth/users/otp/v2/verify/" + this.otpFormControl.value + "?deviceToken=" + this.generatedToken +
          "&voipDeviceToken=" + this.voipToken, isAuth: true,
      };
    } else {
      request = {
        path: "auth/users/otp/v2/verify/" + this.otpFormControl.value + "?deviceToken=" + this.generatedToken, isAuth: true
      };
    }
    let isTokenTemporary = true;
    if (this.mode == 'login' || this.mode == 'signup') isTokenTemporary = false;
    this.coreService.presentLoader(this.constantService.WAIT);
    this.apiService.get(request, isTokenTemporary).subscribe((response: Response) => {
      this.coreService.dismissLoader();
      this.ngOtpInput.setValue('');
      if (response["status"]["code"] === this.constantService.STATUS_OK) {
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_SUCCESS);
        localStorage.setItem("authDetails", JSON.stringify(response.data));
        Preferences.set({ key: "userDetails", value: JSON.stringify(response.data) }).then(() => {
          this.authModuleService.loginEmail = this.loginForm.controls.email.value;
          this.modalCtrl.dismiss();
          if (this.qp && this.qp.trim() != '') this.router.navigateByUrl(this.qp)
        });
      } else {
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_ERROR);
      }
    });
  }

  // OTP Validation
  validateOtp(): boolean {
    if (this.otpFormControl.invalid) {
      this.coreService.showToastMessage("Please enter valid OTP", this.coreService.TOAST_ERROR);
      this.ngOtpInput.setValue('');
      return true;
    }
  }

  // Resend OTP
  resendOtp() {
    this.stopTimer();
    let email = this.getEmail();
    if (!email) return this.router.navigate(["/"]);
    let request: Request = {
      path: `auth/users/otp/send?email=${email}`,
      isAuth: true,
    };
    let isTokenTemporary = true;
    if (this.mode == 'login' || this.mode == 'signup') isTokenTemporary = false;
    this.coreService.presentLoader(this.constantService.WAIT);
    this.apiService.get(request, isTokenTemporary).subscribe((response: Response) => {
      this.coreService.dismissLoader();
      if (response["status"]["code"] === this.constantService.STATUS_OK) {
        this.timeLeft = 120;
        this.startTimer();
        this.cd.detectChanges();
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_SUCCESS);
      } else {
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_ERROR);
      }
    });
  }

  // Get Email
  private getEmail(): string {
    if (this.mode == 'signup') {
      return this.authModuleService.signUpData.email;
    } else {
      return this.authModuleService.loginEmail;
    }
  }

  // Time Convert
  secondsToHms(d: number) {
    d = Number(d);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var mDisplay = m > 0 ? m + (m == 1 ? "" : "  ") : "0";
    var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "0";
    if (s < 10) sDisplay = "0" + sDisplay;
    return `0${mDisplay}: ${sDisplay}s`;
  }

  // Start Timer
  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.cd.detectChanges();
      }
    }, 1000);
  }

  // Stop Timer
  stopTimer() {
    clearInterval(this.interval);
  }

  // Back To Login
  bcakToLogin() {
    this.ionViewDidLeave();
  }


  ///////////////////////           Social SignUp User-Detail Step          ///////////////////////

  // Social Login User-Details Form Submit
  submitSocialLoginUserDetails() {
    this.isUserDetailsSubmitted = true;
    if (this.socialSignUpUserDetails.invalid) return;
    let request: Request = {
      path: "auth/users/update",
      data: {
        ...this.socialSignUpUserDetails.getRawValue(),
        phone: this.checkPhoneNumLength(this.socialSignUpUserDetails.value.phone),
      },
      isAuth: true,
    };
    this.coreService.presentLoader(this.constantService.WAIT);
    this.apiService.post(request).subscribe((response: Response) => {
      this.coreService.dismissLoader();
      if (response["status"]["code"] === this.constantService.STATUS_OK) {
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_SUCCESS);
        localStorage.setItem("authDetails", JSON.stringify(response.data));
        Preferences.set({ key: "userDetails", value: JSON.stringify(response.data) });
        this.socialSignUpUserDetails.reset();
        this.modalCtrl.dismiss();
        if (this.qp && this.qp.trim() != '') this.router.navigateByUrl(this.qp)
      } else {
        this.coreService.showToastMessage(response.status.description, this.coreService.TOAST_ERROR);
      }
    });
  }

  // Get Email From Local Storage
  getEmailFromLocalStorage(res: any) {
    if (res) {
      this.socialSignUpUserDetails.controls['email'].patchValue(res.email);
      this.socialSignUpUserDetails.controls['email'].setValue(res.email);
      this.socialSignUpUserDetails.controls['email'].disable();
      this.socialSignUpUserDetails.controls['fullName'].patchValue(res.fullName);
      this.socialSignUpUserDetails.controls['fullName'].setValue(res.fullName);
    }
  }

}
