import { Location } from "@angular/common";
import { Component, NgZone, OnInit, OnDestroy, inject } from "@angular/core";
import { FacebookLogin } from "@capacitor-community/facebook-login";
import { App, URLOpenListenerEvent } from "@capacitor/app";

import { AlertController, Platform } from "@ionic/angular";
import { CoreService, userRole, UserRole } from "./providers/core.service";
import { DataService, Request } from "./providers/data.service";
import { NetworkService } from "./providers/network.service";
import { SplashScreen } from "@capacitor/splash-screen";
import { Preferences } from "@capacitor/preferences";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "./providers/common.service";
import { IMessage } from "@stomp/stompjs";
import { configuration } from "./configuration";
import { ConstantService } from "src/app/providers/constant.service";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { AuthenticationService } from "./providers/authentication.service";
import { Subscription, interval, filter } from "rxjs";
import { NavController } from "@ionic/angular";

// import { Flipper } from "@capacitor-community/flipper";
import { FullScreenNotification } from "capacitor-fullscreen-notification";
import * as _ from "cypress/types/lodash";
// import { ApplePay } from "@fresha/capacitor-plugin-applepay";
import { Capacitor } from "@capacitor/core";
import { Meta } from "@angular/platform-browser";
import { CallkitServiceService } from "./services/callkit/callkit-service.service";
import { BadgeService } from "./services/badge/badge.service";
// import { AppUpdate } from "@capawesome/capacitor-app-update"
import { Camera } from '@capacitor/camera';
import { AudioPermissions } from 'capacitor-audio-permission';

interface queryParams {
  pn: string,
  en: string,
  dt: string,
  pb: number
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  isShowingSplashScreen = false;
  connectedFans: any[] = [];
  private socketSubscription: Subscription;
  intervalId: number;
  userDetails: any;
  badgeCount: number = 0;
  data = {};
  id: any;
  accepted: boolean = false;
  voipResponse: any;

  apiService: DataService;
  _networkService: NetworkService;
  platform: Platform;
  // router;
  _location: Location;
  commonService: CommonService;
  zone: NgZone;
  constantService: ConstantService;
  authService: AuthenticationService;
  navController: NavController;
  coreService: CoreService;
  meta: Meta;
  activatedRouting: ActivatedRoute;
  callkitService: CallkitServiceService;
  constructor(
    private ar: ActivatedRoute,
    private router: Router,
    private badge: BadgeService,
    private alertCtrl: AlertController
  ) {

    if ((typeof window) != 'undefined') {
      this.injectServices();
      if(this.platform.is('android')){
      //   AppUpdate.getAppUpdateInfo().then((val) => {
      //     if(val == undefined || val.currentVersion == undefined || val.availableVersion == undefined){
      //       this.constantService.updateAvailable_android = false;
      //     }else if (parseInt(val.currentVersion) < parseInt(val.availableVersion)) {
      //       this.navController.navigateRoot('update-app-message', { replaceUrl: true });
      //       this.showToastMessage(val.immediateUpdateAllowed);
      //       this.constantService.updateAvailable_android = true;
      //     } else {
      //       this.constantService.updateAvailable_android = false;
      //     }
      //   });
        setTimeout(() => {
          this.requestAllPermissions();
        }, 2000);
      }
      this.initializeApp();
      this.backButton();
      this.hideSplashScreen();
      this.getPublicInfo();
      this.commonService.getAthleteEarnings();
      this.deepLinking();
        this.getBadgeNotificationCount();
    } else {
    }
  }

  addPushNotificationTapListener() {
    PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
      let redirectPath = notification?.notification?.data?.path;
      let athleteId = notification?.notification?.data?.athleteId;
      let eventId = notification?.notification?.data?.eventId;

      if(!redirectPath || redirectPath.trim() == "") return;

      // When athlete clicks on notification about event request
      // whether from Sponsor or a Fan
      // It should lead athlete to schedule page > requests tab > refresh the data
      let openRequestsTab = notification.notification.data.openRequestsTab;
      let queryParamObject: any = { listing: 'true' };
      if (openRequestsTab == true || openRequestsTab == "true") queryParamObject.openRequestsTab = true

      this.router.navigate([redirectPath +
        (redirectPath != '/tabs/schedule' ? '/' : '') +
        (redirectPath == '/profile/athlete' ? athleteId : (redirectPath == 'waitlist/event/' ? eventId : ''))], {
        queryParams: queryParamObject,
      });
    });
  }

  //Request all permissions initially
  async requestAllPermissions(camera = 0, mic = 0, notif = 0) {
    if (!this.platform.is('android')) return;

    let cameraPermCheck = await Camera.checkPermissions();
    if(cameraPermCheck.camera != 'granted')
      await Camera.requestPermissions({ permissions: ['camera'] });

    let audioPermCheck = await AudioPermissions.checkPermissions();
    if(audioPermCheck.audio != 'granted')
      await AudioPermissions.requestPermissions();

    let notifPermCheck = await PushNotifications.checkPermissions();
    if(notifPermCheck.receive != 'granted')
      await PushNotifications.requestPermissions();

  }

  async showToastMessage(immediateUpdateAllowed) {
    let updateBtn = immediateUpdateAllowed ? {
      text: 'Update', handler: () => {
        // AppUpdate.performImmediateUpdate().then((val) => {
        //   if (val.code == 0) {
        //     this.constantService.updateAvailable_android = false;
        //   }
        // })
      }
    } : undefined;
    (await this.alertCtrl.create({
      header: "New app version is available",
      message: "To continue usage of app, you must update the version",
      buttons: ['OK', updateBtn]
    })).present();
  }

  injectServices() {
    this.apiService = inject(DataService);
    this._networkService = inject(NetworkService);
    this.platform = inject(Platform);
    // this.router = inject(Router);
    this._location = inject(Location);
    this.commonService = inject(CommonService);
    this.zone = inject(NgZone);
    this.constantService = inject(ConstantService);
    this.authService = inject(AuthenticationService);
    this.navController = inject(NavController);
    this.coreService = inject(CoreService);
    this.meta = inject(Meta);
    this.activatedRouting = inject(ActivatedRoute);
    this.callkitService = inject(CallkitServiceService);
  }

  async getfullscreenNotification() {
    await FullScreenNotification.addListener("launch", (data) => {
      if (data == undefined) return;
      let dataObject: any = JSON.stringify(data);
      let parseDataObject: any = JSON.parse(data.fullScreenId);
      this.voipResponse = JSON.parse(data.fullScreenId);
      localStorage.setItem("voip-data", parseDataObject);
      let dataObject1: any = data;
      let bidId: any = parseDataObject.id;
      let isBidEvent: any = parseDataObject.bidEventCheck;
      let buttonClicked: any = JSON.stringify(data.actionId);
      let eventId: any = parseDataObject.eventId;
      // To solve caller athlete profile caching issue
      // if (
      //   !this.commonService.callingAthleteDetails ||
      //   this.commonService.callingAthleteDetails == null
      // ) {
      this.commonService.callingAthleteDetails = this.voipResponse;
      // }
      if (dataObject1.hasOwnProperty("actionId")) {
        //check screen locked/onlocked
        //this.accepted = buttonClicked === '"accept"' ? true : false;
        if (buttonClicked === '"accept"') {
          //check call accept/reject
          this.accepted = true;
        } else {
          this.accepted = false;
        }

        if (this.accepted) {
          let request: Request = {
            path: "core/configuration/publicInfo",
            isAuth: true,
          };
          this.apiService.get(request).subscribe((response: any) => {
            if(response.data?.videoApiKey == undefined || response.data?.videoApiKey == null || response.data?.videoApiKey?.trim() == ''){
              this.commonService.isOpentok = false;
            }else{
              this.commonService.isOpentok = response.data?.videoApiKey === 'VONAGE';
            }

            let callProvider = this.commonService.isOpentok ? '/waitlist/opentok-call/' : '/waitlist/call/';

            if (isBidEvent) {
              this.navController.navigateRoot(callProvider + bidId, {
                queryParams: {
                  isBidEvent: isBidEvent.toString(),
                },
                replaceUrl: true
              })
            } else {
              this.navController.navigateRoot(callProvider + eventId, {
                queryParams: {
                  isBidEvent: isBidEvent.toString(),
                },
                replaceUrl: true
              });
            }
            FullScreenNotification.cancelNotification();
          });
        } else {
          this.cancelFullscreenNotification();
        }
      } else {
        // when athlete calls fan, fan's phone will go to incoming-call, then app loads, then schedulepage opens
        // To prevent this, go to incoming call after app is bootstrapped
        setTimeout(() => {
          if (isBidEvent) {
            this.navController.navigateRoot(["/waitlist/incoming-call/" + bidId]);
          } else {
            this.navController.navigateRoot([
              "/waitlist/incoming-call/" + eventId,
            ]);
          }
        }, 1000);
      }
    });
  }

  RemoveInvertedComma(id: string) {
    let updatedId = id.slice(1, -1);
  }

  async cancelFullscreenNotification() {
    await this.disconnectCall();
    await FullScreenNotification.cancelNotification();
  }

  async disconnectCall() {
    let leftTime = await this.voipResponse.remainingTime;

    let request: Request = {
      path: "core/video/updateCall/" + this.voipResponse.id,
      data: {
        remainingTime: leftTime,
      },
      isAuth: true,
    };
    this.apiService.post(request).subscribe((response: Response) => {
      this.coreService.dismissLoader();
    });
    this.router.navigate(["/tabs/schedule"]);
  }

  async ngOnInit() {
    // Add a listener for pushNotificationActionPerformed, 
    // As login-modal will not be called everytime, we have to define one more listener
  if ((typeof window) != 'undefined') {
    // execute addPushNotificationTapListener only client side
    this.addPushNotificationTapListener()
    //this.redirectToAppPlayStore();
    // await this.getBadgeNotificationCount();
    // await this.getBadgeStatus(0);
    await this.commonService.getAthleteEarnings();
    if (this.authService.isAuthenticated()) {
      this.callingAthlete();
    } else {
      this.socketInit();
    }
    const source = interval(60000);
    this.socketSubscription = source.subscribe((val) => this.onlineStatus());
    this.commonService.privacy();
    this.commonService.termcondition();

    if (this.platform.is("ios")) {
      await this.registerVoipNotification();
    } else {
      this.getfullscreenNotification();
    }
    // this.applePayPayment();
  }
}

  // redirectToAppPlayStore() {
  //   if (
  //     Capacitor.getPlatform() == "web" &&
  //     this.platform.platforms().includes("mobileweb") &&
  //     !this.platform.platforms().includes("desktop") &&
  //     this.platform.is("android")
  //   ) {
  //     console.log("in android chrome");
  //     document.getElementById("playstore").click();
  //   }

  //   if (
  //     Capacitor.getPlatform() == "web" &&
  //     this.platform.platforms().includes("mobileweb") &&
  //     !this.platform.platforms().includes("desktop") &&
  //     this.platform.is("ios")
  //   ) {
  //     console.log("in ios chrome");
  //     document.getElementById("ios").click();
  //   }
  // }

  // applePayPayment() {
  //   ApplePay.initiatePayment({
  //     merchantIdentifier: "com.bubble.bubbleapp",
  //     countryCode: "+91",
  //     currencyCode: "INR",
  //     supportedCountries: ["IN"],
  //     supportedNetworks: [
  //       "amex",
  //       "chinaUnionPay",
  //       "cartesBancaires",
  //       "discover",
  //       "eftpos",
  //       "electron",
  //       "idCredit",
  //       "interac",
  //       "JCB",
  //       "maestro",
  //       "masterCard",
  //       "privateLabel",
  //       "quicPay",
  //       "suica",
  //       "visa",
  //       "vPay",
  //     ],
  //     summaryItems: [],
  //     requiredShippingContactFields: ["emailAddress"],
  //     requiredBillingContactFields: ["emailAddress"],
  //     merchantCapabilities: [
  //       "capability3DS",
  //       "capabilityCredit",
  //       "capabilityDebit",
  //       "capabilityEMV",
  //     ],
  //     billingContact: { emailAddress: "" },
  //     shippingContact: { emailAddress: "" },
  //   });
  // }

  async getBadgeStatus(unreadCount: number) {
    this.badge.set(unreadCount);
  }

  getBadgeNotificationCount() {
    if(!this.authService.isAuthenticated()) return;
    let request: any = {
      path: "notification/notification/check/v2",
      isAuth: true,
    };
    this.coreService.presentLoader(this.constantService.WAIT).then(() => {
      this.apiService.get(request).subscribe((response: any) => {
        this.coreService.dismissLoader();
        if (response.status.code === this.constantService.STATUS_OK) {
          this.badgeCount = response?.data?.unreadCount;
          this.getBadgeStatus(this.badgeCount);
        } else {
          this.coreService.showToastMessage(
            response.status.description,
            this.coreService.TOAST_ERROR
          );
        }
      });
    });
  }

  async onlineStatus() {
    let userDetails = await this.coreService.getUserDataFromStorage();
    if (userDetails) {
      this.commonService.athleteOnlineOfflineStatus();
    } else {
      return;
    }
  }
  socketInitSubs;
  private socketInit() {
    // will only execute once, after OTP verification
    this.socketInitSubs = this.commonService.$socketSubject.subscribe(
      () => {
        this.callingAthlete();
      }
    );
  }

  initializeApp(): void {
    Preferences.get({ key: "first_time" }).then(({ value }) => {
      console.log("initial", value);
    });
    this.platform.ready().then((): void => {
      this._networkEventsListener();
      this.initFacebook();
      this.isUserLoggedInFirstTime();
    });
  }

  hideSplashScreen() {
    this.platform.ready().then(async () => {
      SplashScreen.hide({
        fadeOutDuration: 1000,
      });
      setTimeout(() => {
        this.isShowingSplashScreen = false;
      }, 3000);
      //splash time is reduced to 3 sec from 5 sec
    });
  }

  //integrate facebook login
  private async initFacebook() {
    await FacebookLogin.initialize({ appId: "676274650710402" });
  }

  //get common public info
  async getPublicInfo() {
    this.commonService.getPublicInfo();
    //this.userDetails = await this.coreService.getUserDataFromStorage();
  }

  deepLinking() {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        let domain = "";
        if (configuration.state == "production") {
          domain = "portal.bubbleapp.com";
        } else {
          domain = "dev.bubbleapp.com";
        }
        const pathArray = event.url.split(domain);

        const appPath = pathArray.pop();
        if (appPath) {
          this.router.navigateByUrl(appPath);
        }
      });
    });
  }

  private _networkEventsListener(): void {
    this._networkService
      .networkListener()
      .subscribe((networkStatus: boolean): void => {
        const { msg, type }: { msg: string; type: string } = this._statsUpdate(
          networkStatus
        );

        this.coreService.showToastMessage(msg, type);
      });
  }
  private _statsUpdate(networkStatus: boolean): { msg: string; type: string } {
    if (networkStatus)
    this.commonService.initSocket();
    const msg: string = networkStatus
      ? "Internet Status: ONLINE"
      : "Internet Status: OFFLINE",
      type: string = networkStatus
        ? this.coreService.TOAST_SUCCESS
        : this.coreService.TOAST_ERROR;

    return { msg, type };
  }

  backButton() {
    this.platform.backButton.subscribeWithPriority(0, () => {
      // if (this._location.isCurrentPathEqualTo("/bubble-screen")) {
      if (this._location.isCurrentPathEqualTo("tabs/home")) {
        // Show Exit Alert!
        App.exitApp();
      } else {
        this._location.back();
      }
    });
  }

  isUserLoggedInFirstTime() {
    Preferences.get({ key: "first_time" }).then(({ value }) => {
      console.log("first time ", window.location.href, this.router.url);
      let url = window.location.href;
      console.log("url ", url);

      if (!value && this.authService.data.isLoggedIn == false) {
        if (url.indexOf('signup') > -1) {
          console.log("has signup");
          return;
        } else {
          console.log("no signup");
          // this.router.navigate(["/bubble-screen"]);
        }
        // this.router.navigate(["/bubble-screen"]);
      }
    });
  }

  registerNotification() {
    // Some issue with our setup and push will not work
    // PushNotifications.addListener("registrationError", (error: any) => {
    //   alert("Error on registration: " + JSON.stringify(error));
    // });

    if (!this.authService.data.isLoggedIn) {
      return;
    }

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        console.log("recevied push notifi");
        // alert("Push received: " + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        // alert("Push action performed: " + JSON.stringify(notification));
      }
    );
  }

  socketInitialized = false;

  async callingAthlete() {

    if (!this.authService.data.isLoggedIn) {
      return;
    }

    let userRole: userRole = await this.coreService.getUserRoleFromStorage();
    if (userRole == "athlete") {
      return;
    } else {
      this.socketConnected_subscription = this.commonService.$socketConnected.pipe(filter(connected => this.socketInitialized === false)).subscribe(() => {
        this.userDetails = localStorage.getItem("authDetails");
        let value = localStorage.getItem("authDetails");
        this.userDetails = JSON.parse(value);
        this.send(this.userDetails["id"]);
        this.socketInitialized = true;
      });

      this.socket_errors_subscription = this.commonService.$socket_errors.subscribe((message) => {
        console.log("error", message.body);
        alert("Error " + message.body);
      });

      this.socket_receiveCall_subscription = this.commonService.$socket_receiveCall.subscribe((message: IMessage) => {
        this.receiveCall(message);
      });
    }
  }
  socketConnected_subscription: Subscription;
  socket_errors_subscription: Subscription;
  socket_receiveCall_subscription: Subscription;


  receiveCall(message: IMessage) {
    let responseData = JSON.parse(message.body).content;

    let value = localStorage.getItem("authDetails");
    this.userDetails = JSON.parse(value);
    let id = JSON.parse(responseData);
    if (this.userDetails.id != id.userId) {
      return;
    } else {
      if (this.platform.is("ios")) {
        this.commonService.callingAthleteDetails = JSON.parse(
          responseData
        );
      }
      if (
        (this.platform.is("desktop") ||
          this.platform.is("mobileweb")) &&
        !this.platform.is("ios")
      ) {
        this.commonService.callingAthleteDetails = JSON.parse(
          responseData
        );
        if (
          this.commonService.callingAthleteDetails.creatorPersona !==
          "USER"
        ) {
          this.navController.navigateBack([
            "/waitlist/incoming-call/" +
            this.commonService.callingAthleteDetails.id,
          ]);
        } else {
          this.navController.navigateBack(
            [
              "/waitlist/incoming-call/" +
              this.commonService.callingAthleteDetails.eventId,
            ],
            {
              queryParams: {
                bidId: this.commonService.callingAthleteDetails.id,
              },
            }
          );
        }
      }
    }
  }
  wait() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, 2000);
    })
  }
  send(id) {
    let data = JSON.stringify({
      userId: id,
    });
    this.commonService.socket.send("/app/videoBid", {}, data);
  }
  async registerVoipNotification() {
    this.callkitService.addListener("registration", ({ token }: any) => {
      this.commonService.voipToken = token;
      if (token !== undefined && token !== null && token.trim() !== '') {
        localStorage.setItem("voipToken", token);
      }
    });

    this.callkitService.addListener('incomingCall', () => {
      this.commonService.athleteCancelledFlag = false;
      this.commonService.initSocket();
    })

    // start call
    this.callkitService.addListener("callAnswered", (obj: any) => {

      this.commonService.VideoCallAnswer = true;

      this.data = obj.connectionId;
      let request: Request = {
        path: "core/configuration/publicInfo",
        isAuth: true,
      };
      this.apiService.get(request).subscribe((response: any) => {
        
        if(response.data?.videoApiKey == undefined || response.data?.videoApiKey == null || response.data?.videoApiKey?.trim() == ''){
          this.commonService.isOpentok = false;
        }else{
          this.commonService.isOpentok = response.data?.videoApiKey === 'VONAGE';
        }

        let callProvider = this.commonService.isOpentok ? '/waitlist/opentok-call/' : '/waitlist/call/';

        if(!this.commonService.athleteCancelledFlag){
          if (obj.creatorPersona != "USER") {
            this.navController.navigateRoot(callProvider + obj.id, {
              queryParams: {
                isBidEvent: obj.creatorPersona == "USER" ? 'false' : 'true',
              },
              replaceUrl: true
            })
          } else {
            this.navController.navigateRoot(callProvider + obj.eventId, {
              queryParams: {
                isBidEvent: obj.creatorPersona == "USER" ? 'false' : 'true',
              },
              replaceUrl: true
            });
          }
        }else{
          this.commonService.athleteCancelledFlag = false;
        }
        // CallKitVoip?.addListener('incomingCall', () => {
        // this.commonService.initSocket();
      })
    });
    // });

    // end call
    this.callkitService.addListener("endCall", (obj: any) => {
      let callEnded = false;

      console.log(JSON.stringify(obj), `Call has been REJECTED from `);
      let request: Request = {
        path: "core/video/updateCall/" + obj.id,
        data: {
          remainingTime: obj.remainingTime,
        },
        isAuth: true,
      };
      this.apiService.post(request).subscribe((response: Response) => {
        this.coreService.dismissLoader();
        if (!callEnded) {
          callEnded = true;
          if (this.commonService.isIos()) {
            this.callkitService.endCall();
          }
          this.router.navigate(["/tabs/schedule"]);
        }
      });
      setTimeout(() => {
        if (!callEnded) {
          callEnded = true;
          if (this.commonService.isIos()) {
            this.callkitService.endCall();
          }
          this.router.navigate(["/tabs/schedule"]);
        }
        // init plugin, start registration of VOIP notifications
      }, 3000);

    });
    // init plugin, start registration of VOIP notifications
    await this.callkitService.register(); // can be used with `.then()`

    console.log("Push notification has been registered");
  }

  ngOnDestroy(): void {
    this.socketInitSubs?.unsubscribe();
    this.socketSubscription?.unsubscribe();
    this.socketConnected_subscription?.unsubscribe();
    this.socket_errors_subscription?.unsubscribe();
    this.socket_receiveCall_subscription?.unsubscribe();
    this.socketInitialized = false;
  }
}
