import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../providers/authentication.service";
import { CommonService } from "../providers/common.service";
import { NavController } from "@ionic/angular";
// import { Meta } from "@angular/platform-browser";

// interface queryParams {
//   pn: string, 
//   en: string,
//   dt: string,
//   pb: number 
// }

@Injectable({
  providedIn: "root",
})

export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public commonService: CommonService,
    private navController: NavController
    //  private meta: Meta
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    // <meta property="og:title" content="Bubble App" />
    // <meta property="og:description" content="When You Bubble Up, You Win!" />
    // <meta property="og:image" content="assets/images/BubbleAppBanner.svg" />

    // <meta name="twitter:card" content="summary_large_image" />   <!-- or summary_large_image -->
    // <meta name="twitter:title" content="Bubble App" />
    // <meta name="twitter:description" content="When You Bubble Up, You Win!" />
    // <meta name="twitter:image" content="assets/images/BubbleAppBanner.svg" />
    // this.addMetaTags(state);
    if (this.authService.isAuthenticated()) {
      let data = JSON.parse(localStorage.getItem('authDetails'))
      if (!this.commonService.isPhoneAdded()) {
        this.commonService.PresentLoginModal(state.url);
        return false;
      }
      return true;
    }
    // not logged in so redirect to login page with the return url and return false
    // this.router.navigate(["/auth/login"], {
    //   queryParams: { returnUrl: state.url },
    // });
    if (this.router.url == '/tabs/home' && state.url == "/tabs/profile") {
      this.navController.navigateRoot('tabs/schedule', {
        replaceUrl: true
      });
    }
    this.commonService.PresentLoginModal(state.url);
    return false;
  }
  // addMetaTags(state){
    
  //   if(state.url.substring(0, 14)==="/tabs/schedule"){
  //     let qp : queryParams = (state.root.queryParams as queryParams);
      
  //     if(!qp) return;

  //     if(!(qp.dt && qp.en && qp.pb && qp.pn)) return;

  //     // let qp = {
  //     //   pn: 'pn',
  //     //   en: 'en',
  //     //   dt: 'dt',
  //     //   pb: 'pb' 
  //     // }
      
  //     this.meta.addTag({
  //       name: "tag1",
  //       property: 'og:title',
  //       content: qp.pn + " invited to " + qp.en + " on " + qp.dt + " | BubbleApp ", 
  //     });
  //     this.meta.addTag({
  //       name: "tag2",
  //       property: 'og:description',
  //       content: "Bid to join this special event. " + qp.pb + " people already bidded.", 
  //     });
  //     this.meta.addTag({
  //       name: "tag3",
  //       property: 'og:image',
  //       content: "assets/images/BubbleAppBanner.svg", 
  //     });


  //     this.meta.addTag({
  //       name: "tag4",
  //       property: 'twitter:card',
  //       content: "summary_large_image", 
  //     });
  //     this.meta.addTag({
  //       name: "tag5",
  //       property: 'twitter:title',
  //       content: qp.pn + " invited to " + qp.en + " on " + qp.dt + " | BubbleApp ", 
  //     });
  //     this.meta.addTag({
  //       name: "tag6",
  //       property: 'twitter:description',
  //       content: "Bid to join this special event. " + qp.pb + " people already bidded.", 
  //     });
  //     this.meta.addTag({
  //       name: "tag7",
  //       property: 'twitter:image',
  //       content: "assets/images/BubbleAppBanner.svg", 
  //     });
  //   }else{
  //     let metaTagContent = this.meta.getTag('name=tag1')?.content;
  //     if(metaTagContent){
  //         this.meta.removeTag('name=tag1');
  //         this.meta.removeTag('name=tag2');
  //         this.meta.removeTag('name=tag3');
  //         this.meta.removeTag('name=tag4');
  //         this.meta.removeTag('name=tag5');
  //         this.meta.removeTag('name=tag6');
  //         this.meta.removeTag('name=tag7');
  //     }
  //   }
  // }
}
