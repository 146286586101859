import { Injectable } from '@angular/core';
// import { CallData, CallKitVoip } from "capacitor-callkit-voip";

@Injectable({
  providedIn: 'root'
})
export class CallkitServiceService {

  constructor() { }

  addListener(type, callback){
    // CallKitVoip?.addListener(type, (obj: any)=>{
    //   callback(obj)
    // });
  }
  endCall(){
    // CallKitVoip?.endCall();
  }
  register(){
    // CallKitVoip?.register()
  }
}
