import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/providers/core.service';
import { DataService } from 'src/app/providers/data.service';

@Component({
  selector: 'app-server-route',
  templateUrl: './server-route.component.html',
  styleUrls: ['./server-route.component.scss'],
})
export class ServerRouteComponent implements AfterViewInit {
  constructor(
    private title: Title, 
    private activatedRoute: ActivatedRoute, 
    private meta: Meta, 
    private router: Router, 
    private apiService: DataService, 
    private coreService: CoreService, 
    private httpClient: HttpClient
    ) {}

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(async (val) => {
      if (!val['eventId']) this.router.navigate(['/tabs/schedule']);
      if ((typeof window) != 'undefined') {
  //////////////////////////     CLIENT SIDE RENDERING     //////////////////////////
        let authDetails = JSON.parse(localStorage.getItem('authDetails'));
        if (authDetails?.id == undefined || authDetails?.id == null || authDetails?.id == '') {
          this.router.navigate(["/tabs/home"], {
            queryParams: { returnUrl: encodeURIComponent('/share?eventId=' + val['eventId']) },
          });
          return;
        }
        let request: any = {
          path: "core/event/getEvents",
          data: {
            filter: {
              eventId: val['eventId']
            },
            page: {
              pageLimit: 10,
              pageNumber: 0,
            },
            sort: {
              orderBy: "DESC",
              sortBy: "START_DATE",
            },
          },
          isAuth: true,
        };
        this.apiService.post(request).subscribe((response) => {
          if (response?.data?.content) {
            let eventDetails = response?.data?.content[0];
            if (eventDetails.bidSubmitted || eventDetails.athleteId == authDetails.id) {
              this.router.navigate(['/waitlist/event/' + val['eventId']]);
            } else if (authDetails.userRole === "ATHLETE") {
              this.coreService.showToastMessage("Athlete Can not bid for events", this.coreService.TOAST_WARNING);
              this.router.navigate(['/tabs/schedule']);
            } else {
              this.router.navigate(['/bid-payment/' + val['eventId']]);
            }
          }
        })
      } else {
  //////////////////////////     SERVER SIDE RENDERING     //////////////////////////
        this.activatedRoute.data.subscribe((data: { eventDetails: any }) => {
          if (data.eventDetails) {
            this.setMetaTags(data.eventDetails);
          }
        });
      }
    })
  }

  //////////////////////////     SET META TAGS IN SERVER SIDE RENDERING     //////////////////////////
  setMetaTags(eventDetails: any = '') {
    let qp = {
      pn: eventDetails.athleteName,
      en: eventDetails.eventName,
      pb: eventDetails.count,
    }
    let countString = ((qp.pb == 0) ? " Be the first one to bid. " : ((qp.pb == 1) ? " 1 person already bidded. " : (qp.pb + " people already bidded.")))
    let descriptionContent = (eventDetails.athleteName == null || eventDetails.athleteName == '' || eventDetails.athleteName == undefined) ? ('') : "Bid to join this special event. " + countString
    let titleContent = (eventDetails.athleteName == null || eventDetails.athleteName == '' || eventDetails.athleteName == undefined) ? ('Event not found | BubbleApp') : qp.pn + " invited you to " + qp.en + " | BubbleApp "

    this.meta.addTag({
      name: "description",
      content: descriptionContent,
    });
    this.meta.addTag({
      name: "tag1",
      property: 'og:title',
      content: titleContent,
    });
    this.meta.addTag({
      property: 'og:description',
      content: descriptionContent,
    });
    this.meta.addTag({
      name: "tag2.1",
      property: 'og:type',
      content: "website"
    });
    this.meta.addTag({
      name: "tag2.1",
      property: 'og:type',
      content: "website"
    });
    this.meta.addTag({
      name: "tag3",
      property: 'og:image',
      content: "https://dev-apis.bubbleapp.com/core/file/view?fileKey=%2Fuser%2F63772dc0d2d3671537f3734d%2F20230811101656_BA_2.png",
    });
    this.meta.addTag({
      name: 'twitter:card',
      content: "summary_large_image",
    });
    this.meta.addTag({
      name: 'twitter:title',
      content: titleContent,
    });
    this.meta.addTag({
      name: 'twitter:description',
      content: descriptionContent,
    });
    this.meta.addTag({
      name: 'twitter:image',
      content: "https://dev-apis.bubbleapp.com/core/file/view?fileKey=%2Fuser%2F63772dc0d2d3671537f3734d%2F20230811101656_BA_2.png",
    });
  }
}
