import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { configuration } from 'src/app/configuration';

@Injectable({ providedIn: 'root' })
export class EventDetailsResolver implements Resolve<any> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot) {
    console.log("00000 Resolver Resolve");
    const eventId = route.queryParamMap.get('eventId');
    console.log("00000 Resolver Resolve 2 ", eventId);
    if (eventId) {
      return this.getEventDetails(eventId);
    }
    console.log("00000 Resolver Resolve Return Null ");
    return null;
  }
  
  getEventDetails(eventId: string) : Promise<{athleteName: string, eventName: string, count: number}> {
    return new Promise<{athleteName: string, eventName: string, count: number}>((resolve, reject)=>{
    console.log("00000 Resolver Get Event Details ", configuration.BASE_URL + "core/event/" + eventId);
    fetch(configuration.BASE_URL + "core/event/" + eventId).then((val)=>{
      console.log("00000 Resolver Get Event Details 2 ", val);
      val.json().then((val)=>{
        console.log("00000 Resolver Get Event Details 3 ", val);
        resolve({
          athleteName: val?.data?.athleteName,
          eventName: val?.data?.eventName,
          count: val?.data?.count
        })
      })
    });
  });
  }
  
}