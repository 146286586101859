import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { LoginPageRoutingModule } from "./login-routing.module";

import { LoginPage } from "./login.page";
import { LoginModalComponent } from "./login-modal/login-modal.component";
import { NgOtpInputModule } from "ng-otp-input";
import { VerifyOTPPageRoutingModule } from "../verify-otp/verify-otp-routing.module";
import { CommonDirectiveModule } from "src/app/directives/common-directive.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LoginPageRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule,
    VerifyOTPPageRoutingModule,
    CommonDirectiveModule,
  ],
  declarations: [LoginPage, LoginModalComponent],
})
export class LoginPageModule {}
