import { Injectable } from '@angular/core';
// import { Badge } from '@capawesome/capacitor-badge';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  constructor() { }

  clear(){
    // Badge.clear();
  }

  set(unreadCount: number){
    // return Badge.set({ count: unreadCount });
  }
}
