import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { EMAIL_PATTERN } from "src/app/helpers/emailValidation";
import { ConstantService } from "src/app/providers/constant.service";
import { CoreService } from "src/app/providers/core.service";
import { DataService, Request, Response } from "src/app/providers/data.service";
import { Preferences } from "@capacitor/preferences";
import { NavController, Platform } from "@ionic/angular";
import {
  FacebookLogin,
  FacebookLoginResponse,
} from "@capacitor-community/facebook-login";
import { CommonService } from "src/app/providers/common.service";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { AuthModuleService } from "../auth-module.service";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { _FirebaseMessagingName } from "firebase/messaging/sw";
import {
  SignInWithApple,
  AppleSignInResponse,
  AppleSignInErrorResponse,
  ASAuthorizationAppleIDRequest,
} from "@awesome-cordova-plugins/sign-in-with-apple/ngx";
import { CallkitServiceService } from "src/app/services/callkit/callkit-service.service";
import { configuration } from "src/app/configuration";
declare var AppleID;
@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {
  isFormSubmitted = false;
  isShowingPassword: boolean = false;
  authPublicInfo: any;
  loginForm: FormGroup = new FormGroup({
    email: new FormControl<string | null>(null, [
      Validators.required,
      Validators.pattern(EMAIL_PATTERN),
    ]),
    password: new FormControl<string | null>(null, [Validators.required]),
  });
  returnUrl: string;
  generatedToken: string | null = null;
  FACEBOOK_PERMISSIONS: string[] = [
    "email",
    "user_birthday",
    "user_photos",
    "user_gender",
  ];
  isIOSPlatform: boolean = false;
  isdesktopPlatform: boolean = false;
  voipToken: string = undefined;
  constructor(
    private coreService: CoreService,
    private apiService: DataService,
    private constantService: ConstantService,
    private router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
    public platform: Platform,
    private authModuleService: AuthModuleService,
    private afMessaging: AngularFireMessaging,
    private signInWithApple: SignInWithApple,
    private navController: NavController,
    private callkitService: CallkitServiceService
  ) {
    if((typeof window)=='undefined') return;
    GoogleAuth.initialize({
      clientId:
        "573316732862-ihcsn2uu3cvnhq115s1ejvnbv5mko29t.apps.googleusercontent.com",
      scopes: ["profile", "email"],
    });
    // this.applesign();
    // this.registerVoipNotification();
  }

  ngOnInit() {
    if((typeof window)=='undefined') return;
    this.getAuthPublicInfo();
    console.log("p ", this.commonService.platform.platforms(), this.commonService.platform.is("android"));
    this.checkPlatform();
  }

  checkPlatform() {
    this.isIOSPlatform =
      !this.commonService.platform.is("android") && !this.commonService.platform.is("desktop")
        ? true
        : false;
    this.isdesktopPlatform = this.commonService.platform.is('desktop')
  }
  ionViewDidEnter(){
    if((typeof window)=='undefined') return;
    this.commonService.loadVideo('assets/video/video.mp4').then((source) => {
      let element = (document.getElementById('login-page-video') as HTMLVideoElement);
      element.src = source;
      element.play();
    })
  }
  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  async ionViewWillEnter() {
    if((typeof window)=='undefined') return;
    this.generateNotificationToken();
    // this.registerVoipNotification();
    this.requestPushNotificationsPermission();
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/tabs/home";
  }
  getAuthPublicInfo() {
    let request: Request = {
      path: "auth/configuration/publicInfo",
      isAuth: false,
    };
    this.apiService.get(request).subscribe((response: Response) => {
      this.authPublicInfo = response.data;

      if(response.data?.videoApiKey == undefined || response.data?.videoApiKey == null || response.data?.videoApiKey?.trim() == ''){
        this.commonService.isOpentok = false;
      }else{
        this.commonService.isOpentok = response.data?.videoApiKey === 'VONAGE';
      }
    });
  }
  showPasswordToggle() {
    this.isShowingPassword = !this.isShowingPassword;
  }

  async onSubmit() {
    this.isFormSubmitted = true;
    let voipToken = localStorage.getItem("voipToken");
    if (voipToken !== undefined && voipToken !== null && voipToken.trim() !== '') {
      this.voipToken = voipToken;
    }

    console.log("local", this.voipToken);

    if (this.loginForm.invalid) {
      return;
    }
    // this.registerVoipNotification();

    let request: Request = {
      path: "auth/users/login",
      data: {
        ...this.loginForm.value,
        loginSource: "WEB",
        deviceToken: this.generatedToken,
        voipDeviceToken: this.voipToken,
      },
    };
    console.log("request login", request, "voip token:", this.voipToken);

    this.coreService.presentLoader(this.constantService.WAIT);
    if (this.authPublicInfo.twoStepAuthentication) {
      this.apiService.post(request, false).subscribe((response: Response) => {
        this.coreService.dismissLoader();
        if (response.status.code === this.constantService.STATUS_OK) {
          console.log("eaning", this.commonService.voipToken);

          localStorage.setItem("authDetails", JSON.stringify(response.data));
          Preferences.set({
            key: "userDetails",
            value: JSON.stringify(response.data),
          }).then(() => {
            this.authModuleService.loginEmail = this.loginForm.controls.email.value;
            this.navController.navigateRoot("auth/verify-otp", {
              queryParams: {
                mode: "login",
                returnUrl: this.returnUrl,
              },
              replaceUrl: true
            });
          });
        } else {
          this.coreService.showToastMessage(
            response.status.description,
            this.coreService.TOAST_ERROR
          );
        }
      });
    } else {
      this.apiService.post(request, false).subscribe((response: Response) => {
        this.coreService.dismissLoader();
        if (response.status.code === this.constantService.STATUS_OK) {
          this.commonService.getAthleteEarnings();
          localStorage.setItem("authDetails", JSON.stringify(response.data));
          Preferences.set({
            key: "userDetails",
            value: JSON.stringify(response.data),
          }).then(() => {
            this.authModuleService.loginEmail = this.loginForm.controls.email.value;
            this.navController.navigateRoot("/tabs/home", {
              replaceUrl: true
            });
          });
        } else {
          this.coreService.showToastMessage(
            response.status.description,
            this.coreService.TOAST_ERROR
          );
        }
      });
    }
  }

  socialLogin(data) {
    console.log("afnjseknfken 6 ");
    let voipToken = localStorage.getItem("voipToken");
    if (voipToken !== undefined && voipToken !== null && voipToken.trim() !== '') {
      this.voipToken = voipToken;
    }
    let request: Request = {
      path: "auth/users/login",
      data: {
        socialAccessToken: data.socialAccessToken,
        socialLoginType: data.socialLoginType,
        deviceToken: this.generatedToken,
        voipDeviceToken: this.voipToken,
      },
    };

    this.coreService.presentLoader(this.constantService.WAIT);
    this.apiService.post(request, false).subscribe((response: Response) => {
      this.coreService.dismissLoader();
      if (response.status.code === this.constantService.STATUS_OK) {
        console.log("social");
        localStorage.setItem("authDetails", JSON.stringify(response.data));

        this.coreService.showToastMessage(
          response.status.description,
          this.coreService.TOAST_SUCCESS
        );
        Preferences.set({
          key: "userDetails",
          value: JSON.stringify(response.data),
        }).then(() => {
          if (!response.data.birthDate) {
            this.router.navigate(["/home/user/edit-profile"], {
              queryParams: {
                isProfileComplete: false,
              },
            });
          } else {
            this.navController.navigateRoot(decodeURIComponent(this.returnUrl), {
              replaceUrl: true
            })
          }
        });
      } else {
        this.coreService.showToastMessage(
          response.status.description,
          this.coreService.TOAST_ERROR
        );
      }
    });
  }

  ionViewDidLeave() {
    this.isFormSubmitted = false;
    this.loginForm.reset();
  }

  //google sign in
  async googleSignIn() {
    // this.coreService.showToastMessage(
    //   "Development under progress",
    //   this.coreService.TOAST_INFO
    // );
    try {
      let user = await GoogleAuth.signIn();

      let RequestData = {
        socialAccessToken: user.authentication.accessToken,
        socialLoginType: "GOOGLE",
      };

      this.socialLogin(RequestData);
    } catch (e) { }
  }

  //facebook login

  async faceBookSignIn() {
    console.log("afnjseknfken 2 ");
    if (!this.commonService.platform.is("desktop")) {
      console.log("afnjseknfken 3 ");
      try {
        let result = (await FacebookLogin.login({
          permissions: this.FACEBOOK_PERMISSIONS,
        // })) as FacebookLoginResponse;
        })) as any;
        console.log("afnjseknfken 4 ");
        let RequestData = {
          socialAccessToken: result.accessToken.token,
          socialLoginType: "FACEBOOK",
        };
        this.socialLogin(RequestData);
      } catch (e) {
        console.log("afnjseknfken 1 ", e);

      }
    } else {
      this.coreService.showToastMessage(
        "Development under progress",
        this.coreService.TOAST_INFO
      );
    }
  }

  async generateNotificationToken() {
    if (this.commonService.platform.is("desktop") || this.commonService.platform.is("mobileweb")) {
      this.generatedToken = null;
    }

    let result = await PushNotifications.checkPermissions();

    if(result.receive != 'granted')
      result = await PushNotifications.requestPermissions();
    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      this.generatedToken = token.value;
      // alert("Push registration success, token: " + token.value);
    });

    PushNotifications.addListener("registrationError", (error: any) => {
      // alert("Error on registration: " + JSON.stringify(error));
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        // alert("Push received: " + JSON.stringify(notification));
      }
    );

    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        // alert("Push action performed: " + JSON.stringify(notification));
      }
    );
    //this.registerVoipNotification();
    if (result.receive === "granted") {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
    } else {
      this.generatedToken = null;
      // Show some error
    }
  }

  requestPushNotificationsPermission() {
    // requesting permission
    this.afMessaging.requestToken // getting tokens
      .subscribe(
        (token) => {
          // USER-REQUESTED-TOKEN
          this.generatedToken = token;
          console.log("Permission granted! Save to the server!", token);
        },
        (error) => {
          console.error(error);
        }
      );
  }
  getMessage() {
    this.afMessaging.messages.subscribe((e) => {
      console.log(e);
    });
  }

  async goBack() {
    await localStorage.removeItem("authDetail");
    this.navController.navigateRoot("/bubble-screen", {
      replaceUrl: true
    });
  }

  async applesign() {
    if (this.isIOSPlatform) {
      this.signInWithApple
        .signin({
          requestedScopes: [
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
            ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
          ],
        })
        .then((res: AppleSignInResponse) => {
          const appleSigninToken = res.identityToken.split('.')[0] + '.' + btoa(JSON.stringify({...JSON.parse(atob(res.identityToken.split('.')[1])), ...(res.fullName?.givenName && res.fullName?.familyName ? { firstName: res.fullName.givenName, lastName: res.fullName.familyName } : {})})) + '.' + res.identityToken.split('.')[2];
          let RequestData = {
            socialAccessToken: appleSigninToken,
            socialLoginType: "APPLE",
          };
          this.socialLogin(RequestData);
        })
        .catch((error: AppleSignInErrorResponse) => {
          console.error(error);
        });
    } else if (this.isdesktopPlatform) {
      let isProd = configuration.BASE_URL.includes("prod-apis")
      AppleID.auth.init({
        clientId: 'com.bubble.bubbleapp.home',
        scope: 'name email',
        redirectURI: "https://" + (isProd ? "portal" : "dev") + '.bubbleapp.com/home/user/edit-profile',
        state: 'init',
        nonce: 'test',
        usePopup: true
      });
      const data = await AppleID.auth.signIn();
      const appleSigninToken = data.authorization.id_token.split('.')[0] + '.' + btoa(JSON.stringify({ ...JSON.parse(atob(data.authorization.id_token.split('.')[1])), ...(data?.user?.name?.firstName && data?.user?.name?.lastName ? { firstName: data.user.name?.firstName, lastName: data.user.name?.lastName } : {}) })) + '.' + data.authorization.id_token.split('.')[2];
      let RequestData = {
        socialAccessToken: appleSigninToken,
        socialLoginType: "APPLE",
      };
      this.socialLogin(RequestData);
    }
  }

  // async applesignDesktop() { 
  //   AppleID.auth.init({
  //     clientId: 'com.bubble.bubbleapp.home',
  //     scope: 'name email',
  //     redirectURI: 'https://dev.bubbleapp.com/home/user/edit-profile',
  //     state: 'init',
  //     nonce: 'test',
  //     usePopup: true
  //   });
  //   const data = await AppleID.auth.signIn();
  //   const appleSigninToken = data.authorization.id_token.split('.')[0] + '.' + btoa(JSON.stringify({ ...JSON.parse(atob(data.authorization.id_token.split('.')[1])), ...(data?.user?.name?.firstName && data?.user?.name?.lastName ? { firstName: data.user.name?.firstName, lastName: data.user.name?.lastName } : {}) })) + '.' + data.authorization.id_token.split('.')[2];
  //   let RequestData = { 
  //     socialAccessToken: appleSigninToken,
  //     socialLoginType: "APPLE",
  //   };
  //   this.socialLogin(RequestData);
  // }

  async registerVoipNotification() {
    // register token
    console.log("VOIP funv call");
    
    this.callkitService.addListener("registration", ({ token }: any) => {
      console.log(`VOIP token has been received login123 ${token}`);
      this.voipToken = token;
      console.log("common login", this.voipToken);
    });

    // start call
    this.callkitService.addListener("callAnswered", (obj) =>
      console.log(`Call has been received from`, JSON.stringify(obj))
    );
    // end call
    this.callkitService.addListener("endCall", (obj) =>
      console.log(
        JSON.stringify(obj),
        obj.connectionId,
        `Call has been REJECTED from `
      )
    );
    // init plugin, start registration of VOIP notifications
    await this.callkitService.register(); // can be used with `.then()`
    console.log("Push notification has been registered");
  }
}
