import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { CoreService } from "../providers/core.service";
import { Router } from "@angular/router";
import { Preferences } from '@capacitor/preferences';
import { BadgeService } from "../services/badge/badge.service";
// import { coreService } from "../providers/coreService";

@Injectable({
  providedIn: "root",
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private coreService: CoreService, private router: Router, private badge: BadgeService) {} // private readonly coreService: coreService
  /***
   * description: http intercepter to handle API errors
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        this.coreService.dismissLoader();

        if (err.status === 401) {
          this.loggedOutFromThisDevice();
        }
        return throwError(err);
      })
    );
  }
  async loggedOutFromThisDevice(){
    await this.coreService.showToastMessage(
      'You have been logged out from this device',
      this.coreService.TOAST_ERROR
    );
    let voipToken = localStorage.getItem("voipToken");
    let firstTimeLoaded = localStorage.getItem("firstTimeLoaded");
    localStorage.clear();
    this.badge.clear();
    if (voipToken && voipToken.trim() != '') localStorage.setItem("voipToken", voipToken);
    if (firstTimeLoaded && firstTimeLoaded.trim() != '')localStorage.setItem("firstTimeLoaded", firstTimeLoaded);
    await Preferences.clear();
    // await this.router.navigateByUrl("/auth/login");
    await this.router.navigateByUrl("/tabs/home");
    location.reload();
  }
}
