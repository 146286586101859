import { Injectable } from "@angular/core";
import { format, parseISO } from "date-fns";
import { BehaviorSubject, filter, Subject, Subscription } from "rxjs";
import { ConstantService } from "./constant.service";

import { AuthenticationService } from "./authentication.service";
import { DataService, Request, Response } from "./data.service";
import { CompatClient, IMessage, Stomp } from "@stomp/stompjs";
import * as SockJS from 'sockjs-client';
import { configuration } from "../configuration";
import { Preferences } from "@capacitor/preferences";
import { userRole } from "./core.service";
import { HttpClient } from "@angular/common/http";
import { ModalController, NavController, Platform, ToastController, ToastOptions } from "@ionic/angular";
import { CallkitServiceService } from "../services/callkit/callkit-service.service";
import { LoginModalComponent } from "../pages/auth-module/login/login-modal/login-modal.component";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public publicInfo: any;
  isOpentok : boolean = false;
  $reportUser: Subject<void>= new Subject();

  // socket subscription in call component flag, if true, then its subscribed
  socketSubscriptionFlag = false;
  athleteCancelledFlag = false;
  
  public $profileSubject: Subject<void> = new Subject();
  profileUrl: string = "";
  callingAthleteDetails: any | null = null;
  callingFanDetail: any | null = null;
  athleteEarning: any;
  badgeCount: number = 0;
  privacypolicy: any;
  termconditions: any;
  athleteEarnings: number = 0;
  voipToken: any;
  VideoCallAnswer: boolean = false;
  bidAmount : any = 0;
  fanEventType: "VIDEO" | "IN_PERSON" = "VIDEO";
  // IOSAppVersion : string = "v3.0";
  // androidAppVersion : string = "v3.0";
  // IOSBuildVersion : string = "4";
  // androidBuildVersion : string = "4";
  IOSAppVersion : string = "";
  androidAppVersion : string = "";
  IOSBuildVersion : string = "";
  androidBuildVersion : string = "";

  public $socketSubject: Subject<void> = new Subject();
  public $navigateSubject: Subject<void> = new Subject();
  constructor(
    private apiService: DataService,
    public constantService: ConstantService,
    public authenticationService: AuthenticationService,
    private httpClient : HttpClient,
    public platform: Platform,
    private callkitService: CallkitServiceService,
    private navController: NavController,
    private toastCtrl: ToastController,
    public modalCtrl: ModalController,
  ) {
    if((typeof window)=='undefined') return;
    if(!this.socket){
      this.initSocket();
    }
  }

  public _calculateAge(birthday: Date) {
    // birthday is a date
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  getInitials(fullName: String): string {
    if(!this.authenticationService.isAuthenticated()) return;
    let trimmedName = fullName?.trim();
    let splitName = trimmedName.split(" ");
    let firstName = splitName[0];
    let lastName = splitName[1];

    if (lastName) {
      return firstName[0] + lastName[0];
    } else {
      return firstName[0];
    }
  }
  getInitialsWithoutLogin(fullName: String): string {
    let trimmedName = fullName?.trim();
    let splitName = trimmedName.split(" ");
    let firstName = splitName[0];
    let lastName = splitName[1];

    if (lastName) {
      return firstName[0] + lastName[0];
    } else {
      return firstName[0];
    }
  }
  getFirstAndLastName(fullName: String): string[] {
    let trimmedName = fullName?.trim();
    let splitName = trimmedName.split(" ");
    return splitName;
  }
  async getUserRoleFromStorage(): Promise<userRole> {
    const { value } = await Preferences.get({ key: "userDetails" });
    let userData = JSON.parse(value);
    if(userData==null || userData==undefined){
      return undefined;
    }else{
      return this.getUserType(userData.roles);
    }
  }

  formatDate(value: string) {
    return format(parseISO(value), "MM/dd/yyyy");
  }

  formatDateTime(value: string) {
    return format(parseISO(value), " MMM dd,yyyy, hh:mm aaaaa'm'");
  }
  formatDateTimeUpdated(value: string) {
    return format(parseISO(value), " MMM dd,yyyy");
  }
  formatTime(value: string) {
    return format(parseISO(value), "HH:mm ");
  }

  getUserType(userRole: string[]): "athlete" | "fan" {
    let isAthlete = userRole.some((role) => role === "ATHLETE");
    if (isAthlete) {
      return "athlete";
    } else {
      return "fan";
    }
  }
  isIos(){
    return (this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone'));
  }

  isPhoneAdded(): string {
    // let localStorageData = JSON.parse(localStorage.getItem('authDetails'))
    // return localStorageData?.phone
    let phoneNum: string | undefined = JSON.parse(localStorage.getItem('authDetails'))?.phone
    return (phoneNum && phoneNum.trim()!='') ? phoneNum : undefined
  }

  loadVideo(fileName: string): Promise<string>{
    return new Promise((resolve, reject)=>{
      let isIos = this.isIos();
      if(!isIos){
        resolve(fileName)
        return;
      }
      this.httpClient
        .get(
          'capacitor://localhost/'+fileName, { responseType: 'blob' }
        )
        .subscribe(
          (val) => {
            console.log('sdgnjkasg ', val);
            let fileReader = new FileReader();
            fileReader.onloadend = () => {
              resolve((fileReader.result as string));
            }
            fileReader.readAsDataURL(val)
          }
        );

    })
  }
  getPublicInfo() {
    let request: Request = {
      path: "core/configuration/publicInfo",
      isAuth: true,
    };
    return new Promise<void>((resolve) => {
    this.apiService.get(request).subscribe((response: Response) => {
      this.publicInfo = response.data;
      if(response.data?.videoApiKey == undefined || response.data?.videoApiKey == null || response.data?.videoApiKey?.trim() == ''){
        this.isOpentok = false;
      }else{
        this.isOpentok = response.data?.videoApiKey === 'VONAGE';
      }
      resolve(this.publicInfo);
    });
  })
  }
  async getAthleteEarnings() {
    let userRole;
    if(this.authenticationService.isAuthenticated()){
      userRole = await this.getUserRoleFromStorage();
    }
    // let userRole: userRole = await this.core.getUserRoleFromStorage();
    if (this.authenticationService.isAuthenticated() && userRole==='athlete') {
      let request: any = {
        path: "core/event/athlete/cash",
        isAuth: true,
      };
      this.apiService.get(request).subscribe((response: any) => {
        if (response.status.code === this.constantService.STATUS_OK) {
          this.athleteEarnings = response?.data?.totalEarning;
          console.log("athleteEarnings", this.athleteEarnings);

          this.athleteEarning = this.athleteEarnings;
        }
      });
    }
  }
  socket : CompatClient;
  socket_errors : Subject<IMessage> = new Subject();
  socket_receiveCall : Subject<IMessage> = new Subject();
  socket_cancelCall : Subject<IMessage> = new Subject();
  socket_bidList : Subject<IMessage> = new Subject();
  socketConnected : BehaviorSubject<boolean> = new BehaviorSubject(false);

  $socket_errors = this.socket_errors.asObservable();
  $socket_receiveCall = this.socket_receiveCall.asObservable();
  $socket_cancelCall = this.socket_cancelCall.asObservable();
  $socket_bidList = this.socket_bidList.asObservable();
  $socketConnected = this.socketConnected.asObservable().pipe(filter(connected => connected === true));
  
  socketSubscriptions=[];

  initSocket(){
    this.socket?.disconnect();

    this.socket = Stomp.over(
      // () => new SockJS('http://192.168.27.118:8102/' + "core/greeting")
      () => new SockJS(configuration.BASE_URL + "core/greeting")
    );
    this.socket.reconnect_delay = 5000;
    
    this.socket.connect({}, 
      (frame)=>{
        this.socketConnected.next(true);
        let errorsSubs = this.socket.subscribe("/errors", (message) => {
          this.socket_errors.next(message);
        });
        let receiveCall = this.socket.subscribe("/topic/receiveCall", async (message)=>{
          const { value } = await Preferences.get({ key: "userDetails" });
          let userDetails = JSON.parse(value);
          let msg = JSON.parse(JSON.parse(message.body).content);
          if (userDetails.id == msg.userId || userDetails.id == msg.athleteId) {
            this.socket_receiveCall.next(message);
          }
        })
        this.socket.subscribe("/topic/cancelCall", async (message)=>{
          const { value } = await Preferences.get({ key: "userDetails" });
          let userDetails = JSON.parse(value);
          let msg = JSON.parse(JSON.parse(message.body).content);
          if (userDetails.id == msg.userId || userDetails.id == msg.athleteId) {
            if(this.isIos()){
              this.callkitService.endCall();
            }
            this.socket_cancelCall.next(message);
            let userRole: userRole = await this.getUserRoleFromStorage();
            if((!this.socketSubscriptionFlag) && userRole == 'fan'){
              this.athleteCancelledFlag = true;
              this.navController.navigateRoot('/tabs/schedule', {replaceUrl: true});
            }
          }
        })
        this.socket.subscribe("/topic/bidList", (message)=>{
          this.socket_bidList.next(message);
        })

        this.socketSubscriptions.push(errorsSubs);
      },
      (error)=>{
        console.log("STOMP error " + error);
      }
    );
  }
  athleteOnlineOfflineStatus() {
    let request: Request = {
      path: "auth/users/manage/status/change/true",
      isAuth: true,
    };
    this.apiService.get(request).subscribe((response: Response) => {});
  }

  removeCommaFromString(value: string) {
    let converted = parseFloat(value.replace(/,/g, ""));

    return converted;
  }
  convertTimeToMinute(hour: string, min: string) {
    return Number(hour) * 60 + Number(min);
  }

  dateFormat(startDate: Date) {
    var countDownDate = new Date(startDate).getTime();
    let days;
    let hours;
    let minutes;
    let seconds;
    let now;
    let timeleft;

    now = new Date().getTime();
    timeleft = countDownDate - now;
    days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

    let counter;
    if (days >= 1) {
      counter = {
        days: days,
        title_days: "days",
        hours: hours,
        title_hours: "hours",
        minutes: minutes,
        title_min: "mins",
        sec: seconds,
        title_sec: "S",
      };
    } else {
      if (days < 1 && hours < 1) {
        counter = {
          minutes: minutes,
          title_min: "mins",
          sec: seconds,
          title_sec: "S",
        };
      } else {
        counter = {
          hours: hours,
          title_hours: "hours",
          minutes: minutes,
          title_min: "mins",
          sec: seconds,
          title_sec: "S",
        };
      }
    }

    return counter;
  }
  secondsToHms(seconds: number) {
    seconds = Number(seconds);

    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor((seconds % 3600) % 60);

    var mDisplay = m > 0 ? m + (m == 1 ? "" : "  ") : "0";
    var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "0";

    if (s < 10) {
      sDisplay = "0" + sDisplay;
    }

    return `0${mDisplay}: ${sDisplay}s`;
  }

  getBadgeNotificationCount() {
    if(!this.authenticationService.isAuthenticated()) return;
    let request: any = {
      path: "notification/notification/check/v2",
      isAuth: true,
    };
    this.apiService.get(request).subscribe((response: any) => {
      this.badgeCount = response.data.unreadCount;
      console.log("c ", this.badgeCount);
      return this.badgeCount;
    });
  }
  async privacy() {
    let request: any = {
      path: "auth/configuration/getPrivacyPolicy",
    };
    this.apiService.get(request).subscribe((response: any) => {
      this.privacypolicy = response.data;
      return this.privacypolicy;
    });
  }
  termcondition() {
    let request: Request = {
      path: "auth/configuration/getTermsAndCondition",
    };
    this.apiService.get(request).subscribe((response: any) => {
      this.termconditions = response.data;
      return this.termconditions;
    });
  }
   //////////////////////////////////////////// FOR EVENT SUBSCRIPTION ////////////////////////////////////////////
  /**
   * Subscribe to a topic and provide a single handler/observer.........
   * @param topic The name of the topic to subscribe to.
   * @param observer The observer or callback function to listen when changes are published.
   *
   * @returns Subscription from which you can unsubscribe to release memory resources and to prevent memory leak.
   */
  private channels: { [key: string]: Subject<any> } = {};
  subscribe(topic: string, observer: (_: any) => void): Subscription {
    if (!this.channels[topic]) {
      // You can also use ReplaySubject with one concequence
      this.channels[topic] = new Subject<any>();
    }
    return this.channels[topic].subscribe(observer);
  }
  /**
   * Publish some data to the subscribers of the given topic.
   * @param topic The name of the topic to emit data to.
   * @param data data in any format to pass on.
   */
  publish(topic: string, data?: any): void {
    const subject = this.channels[topic];
    if (!subject) {
      // Or you can create a new subject for future subscribers
      return;
    }
    subject.next(data);
  }
  /**
   * When you are sure that you are done with the topic and the subscribers no longer needs to listen to a particular topic, you can
   * destroy the observable of the topic using this method.
   * @param topic The name of the topic to destroy.
   */
  destroy(topic: string): null {
    const subject = this.channels[topic];
    if (!subject) {
      return;
    }
    subject.complete();
    delete this.channels[topic];
  }
  giveVoipToken(){
    this.voipToken = localStorage.getItem('voipToken');
    if(this.authenticationService.isAuthenticated() && this.voipToken!=null && this.voipToken!=undefined && this.voipToken.trim()!=''){
      let request = {
        path: `auth/users/voip-token?voipToken=${this.voipToken}`,
        isAuth: true
      }
      this.apiService.get(request).subscribe((response: any)=>{
        console.log("TOKEN UPLOAD RESPONSE ", response);
      })
    }
  }

  getauthDetailsFromLocalStorage() {
    return localStorage.getItem("authDetails");
  }
  
  async PresentLoginModal(redirectUri: any = ''): Promise<Boolean> {
    return new Promise<Boolean>(async (resolve, reject)=>{
      const modal: HTMLIonModalElement = await this.modalCtrl.create({
        component: LoginModalComponent,
        cssClass: "client-filter-modal",
        componentProps: {key: redirectUri}
      });
      modal.present();
      modal.onWillDismiss().then(()=>{
        resolve(this.authenticationService.isAuthenticated())
      })
    })

  }
}
